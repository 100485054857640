@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    background-color: #121312;
    @apply font-montserrat
}

::-webkit-scrollbar {
    width: 8px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #646363;
    border-radius: 10px;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #2c2b2b;
    border-radius: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #3a3939;
  }